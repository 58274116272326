.logo {
  width: 50% !important;
  margin-left: auto;
  margin-right: auto;
}

.header-logo {
  width: 100%;
}

.ta-center {
  text-align: center;
}

.ta-right {
  text-align: right;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

/* .d-flex {
  display: flex;
} */

.al-center {
  align-items: center;
}

.al-center {
  align-items: center;
}

.al-fs {
  align-items: flex-start;
}

.mt-20 {
  margin-top: 20px !important;
}

.m-10 {
  margin: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.w-200 {
  width: 200px !important;
}

.w-20 {
  width: 20% !important;
}

.card-center {
  margin: 0 auto;
  margin-top: 30px;
}

.block-item-center {
  margin: 0 auto;
}

.book-button-color {
  border: 0px !important;
  background-color: #70ad47 !important;
  font-size: large !important;
  color: rgba(197, 224, 180) !important;
}

.book-button-color.active {
  background-color: #406128 !important;
}

.music-button-color {
  color: #1f4e79 !important;
  border: 0px !important;
  background-color: #9dc3e6 !important;
}

.music-button-color.active {
  background-color: #4890cf !important;
}

.textbook-button-color {
  color: #7f6000 !important;
  border: 0px !important;
  background-color: #ffc000 !important;
}

.textbook-button-color.active {
  background-color: #997200 !important;
}

.broadcast-button-color {
  color: #c00000 !important;
  border: 0px !important;
  background-color: #ff9999 !important;
}

.broadcast-button-color.active {
  background-color: #ff3232 !important;
}

.paper-button-color {
  font-size: large !important;
  color: #7f7f7f !important;
  border: 0px !important;
  background-color: #d9d9d9 !important;
}

.paper-button-color.active {
  background-color: #a5a5a5 !important;
}

.internet-button-color {
  color: #b4c7e7 !important;
  border: 0px !important;
  background-color: #4472c4 !important;
}

.internet-button-color.active {
  background-color: #254478 !important;
}

.dance-button-color {
  color: #fbe5d6 !important;
  border: 0px !important;
  background-color: #ed7d31 !important;
}

.dance-button-color.active {
  background-color: #a74c0f !important;
}

.smallCategory-button.active {
  background-color: #004289 !important;
}

.publisher-button-size {
  width: 30%;
  height: 100px;
  font-size: x-large;
  font-weight: bold;
  color: #ffffff;
}

.highlighted-button.active {
  box-shadow: none !important;
  outline: 6px #305496 solid !important;
}

.tree-branch {
  background-color: blue;
}

.border-1-solid {
  border: 1px solid;
}

.w-40p {
  width: 40%;
}

.w-60p {
  width: 60%;
}

.w-80p {
  width: 80%;
}

.w-10p {
  width: 10%;
}

.w-14p {
  width: 14%;
}

.help-area {
  padding-top: 200px;
}

.scroll {
  overflow: scroll;
}

.reports-filter {
  justify-content: flex-end;
  align-items: baseline;
}

.old-select {
  background-color: red;
}

.work-card {
  width: 100%;
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.overlay {
  background: #ffffff;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}

.filter-phase-label {
  width: 200px;
  text-align: right;
}

.filter-label {
  width: 200px;
  text-align: right;
}

.add-button {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.h-50px {
  height: 50px;
}

.copy-icon {
  vertical-align: text-top;
}

.select-copy-from {
  background-color: #ccf1ff61 !important;
  border: 2px solid #65778b !important;
}

.dropdown-menu {
  width: 500px;
}

.special-number-label {
  font-size: 14px;
}

.copy-mark {
  display: inline-block;
  border: 5px solid red;
  color: red;
  font-size: x-large;
}

.rdt_TableCol input[type='checkbox'],
.rdt_TableCell input[type='checkbox'],
.form-check input[type='checkbox'],
.custom-checkbox > .custom-control-label::before,
.custom-checkbox > .custom-control-label::after {
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.login-subtitle {
  padding-top: 1em;
}

.document-icon {
  vertical-align: baseline;
  margin-left: 5px;
}

.lager-settings {
  display: flex;
}

.medium-settings {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.big-toast {
  width: 500px;
  left: -27%;
}

.displayName-position {
  text-align: right;
  word-break: break-all;
  overflow-wrap: break-word;
}

.rdt_TableCol,
.rdt_TableCell {
  padding-right: 2px !important;
  padding-left: 2px !important;
}

.font-small {
  font-size: small !important;
}

.filter-arrow {
  display: flex;
}

.bg-whitesmoke {
  background-color: whitesmoke;
}

.bg-blue {
  background-color: #ddebf7;
}

.bg-green {
  background-color: #e2efda;
}

.bg-yellow {
  background-color: #fff2cc;
}

.bg-orange {
  background-color: #f8cbad;
}

.bg-pink {
  background-color: #ffdddd;
}

.bg-red {
  background-color: #ffb7b7;
}

.admin-filter {
  display: flex;
}

.lh-1 {
  line-height: 1.2;
}

@media (max-width: 991.98px) {
  .lager-settings {
    display: none;
  }

  .filter-arrow {
    display: none;
  }

  .medium-settings {
    display: flex;
  }

  .header-logo {
    width: 60%;
  }

  .displayName-position {
    text-align: left;
  }

  .admin-filter {
    display: block;
  }
}

.rdt_TableHeader{
  min-height: 0px !important;
}